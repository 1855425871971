@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@200..900&display=swap');

:root {
  --background-color: #FFEECE;
  --primary-color: #455C7B;
  --secondary-color: #212C3B;
  --accent-green: #74D595;
  --accent-pink: #F033A8;
}

.logo{
  width: 4rem;
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
}

body {
  text-align: center;
  font-family: 'Unbounded', 'Sans serif';
  font-weight: 300;
  background-color: var(--background-color);
  color: var(--secondary-color);
}

.main-section {
  position: relative;
  padding-top: 3rem;
  min-height: 80dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

h1 {
  font-size: 2.6rem;
  color: var(--primary-color);
  font-weight: 500;
  margin: 1rem;
}

h2 {
  font-size: 1.2rem;
  font-weight: 300;
  margin: 1rem;
}

button {
  margin: 2rem;
  padding: 0.5rem 2.5rem 0.5rem 2.5rem;
  font-size: 2rem;
  text-transform: uppercase;
  font-family: 'Unbounded';
  font-weight: 600;
  color: white;
  background-color: var(--accent-green);
  border: none;
  border-radius: 3rem;
  box-shadow: 0.2rem 0.2rem 0 0 rgb(0,0,0);
  cursor: pointer;
}

button:hover {
  box-shadow: none;
  background-color: var(--accent-pink);
}

button:active {
  box-shadow: inset 0.2rem 0.2rem 0 0 rgb(0,0,0);
  background-color: var(--primary-color);
}

.text-section {
  background-color: var(--primary-color);
  padding: 4rem 2% 5rem 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.text-section > p{
  font-size: 0.9rem;
  color: white;
  width: 75%;
  max-width: 60rem;
}

.portfolio-section {
  display: grid;
  justify-content: center;
  padding: 3rem 0 1rem 0;
}

.portfolio-section > h2 {
  font-weight: 600;
}

.portfolio-items {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 2rem;
  justify-content: center;
  max-width: 60rem;
}

.portfolio-item {
  background-color: white;
  width: 15rem;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0.2rem 0.2rem 0 0 rgb(0,0,0);;
}

.portfolio-item:hover {
  background-color: var(--accent-pink);
  box-shadow: none;
  color: white;
}

.portfolio-item:active{
  box-shadow: inset 0.2rem 0.2rem 0 0 rgb(0,0,0);
  background-color: var(--primary-color);
}

.portfolio-item > img {
  width: 100%;
  height: 10rem;
  object-fit: cover;
}

#second-btn {
  font-size: 1.2rem;
}

.footer {
  background-color: var(--accent-green);
  padding: 1rem 0 1rem 0;
  display: grid;
  justify-content: center;
}

.footer-logo {
  width: 3rem;
  justify-self: center;
}

.footer > p {
  font-size: 0.6rem;
  margin: 0.2rem;
}

.footer-linkedin{
  width: 2rem;
  justify-self: center;
  padding: 1rem 0 1rem 0;
}

@media (max-width: 639px){
  .portfolio-item:hover {
    background-color: white;
    color: var(--secondary-color);
    box-shadow: 0.2rem 0.2rem 0 0 rgb(0,0,0);;
  }

  .portfolio-items{
    padding: 1rem;
    gap: 1rem;
  }
  .portfolio-item{
    width: 10rem;
  }

  .portfolio-item > p{
    font-size: 0.7rem;
  }

  .portfolio-item > h3 {
    font-size: 1rem;
  }

  .portfolio-item > img {
    height: 6rem;
  }
}

@media (max-width: 431px){
  .portfolio-item{
    width: 80%;
    text-align: left;
  }

  .portfolio-item > img {
    width: 100%;
    height: 8rem;
  }
}